<template>
  <div class="editDocument">
    <div class="infoItem">
      <span class="label"> 问题： </span>
      <el-input
        class="form"
        v-model="question"
        placeholder="请输入内容"
        :disabled="justShow"
      ></el-input>
    </div>
    <div class="infoItem">
      <span class="label"> 答案： </span>
      <div class="form" id="wangEditor"></div>
    </div>

    <div class="btns" v-if="!justShow">
      <el-button type="primary" @click="submit">确认保存</el-button>
      <el-button @click="cancel">取消</el-button>
    </div>
  </div>
</template>

<script>
import wangEditor from "wangeditor";
export default {
  data() {
    return {
      htmlEditor: "",
      question: "",
      justShow: false,
    };
  },
  mounted() {
    this.initEditor();
    this.initEditorData();
  },
  methods: {
    initEditor() {
      const { noEdit } = this.$route.query;
      if (noEdit) {
        this.justShow = true;
      }
      this.htmlEditor = new wangEditor("#wangEditor");
      this.htmlEditor.create();
      if (noEdit) {
        this.htmlEditor.disable();
      }
    },
    initEditorData() {
      this.$request
        .getHelpDocumentInfo({
          id: this.$route.query.id,
        })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          const { question, answer } = res?.data || {};
          this.question = question;
          this.htmlEditor.txt.html(answer);
        });
    },
    submit() {
      this.$request
        .addOrUpdateDocument({
          id: this.$route.query.id,
          question: this.question,
          answer: this.htmlEditor.txt.html(),
        })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.$message.success("新建成功");
          this.cancel();
        });
    },
    cancel() {
      this.$router.push("/helpDocuments");
    },
  },
};
</script>

<style lang="scss">
.editDocument {
  background-color: #fff;
  padding: 24px 20%;
  .infoItem {
    display: flex;
    margin-bottom: 20px;
    .label {
      flex-shrink: 0;
      text-align: right;
      vertical-align: middle;
      font-size: 14px;
      color: #606266;
      box-sizing: border-box;
      padding-top: 4px;
      // &::before {
      //   content: "*";
      //   color: #f56c6c;
      //   margin-right: 4px;
      // }
    }
  }
  .btns {
    margin-left: 40px;
  }
}
</style>